
import { Contract } from '@ethersproject/contracts'
import {simpleRpcProvider} from "./providers";
import {
    getUSDTAddress,
    getMulticallAddress,
    getTraderAddress,
    getOTCAddress
} from "./addressHelpers";

// ABI
import MultiCallAbi from '../config/abi/Multicall.json'
import Erc20Abi from '../config/abi/erc20.json'
import TraderAbi from '../config/abi/Trader.json'
import OTCAbi from '../config/abi/OTC.json'
import {getSigner} from "./index";

export const getContract = (abi, address, signer) => {
    const signerOrProvider = signer ? getSigner() : simpleRpcProvider
    return new Contract(address, abi, signerOrProvider)
}

export const getMulticallContract = () => {
    return getContract(MultiCallAbi, getMulticallAddress(), false)
}

export const getUSDTContract = (isSigner) => {
    return getContract(Erc20Abi, getUSDTAddress(), isSigner)
}

export const getTraderContract = (isSigner) => {
    return getContract(TraderAbi, getTraderAddress(), isSigner)
}

export const getOTCContract = (isSigner) => {
    return getContract(OTCAbi, getOTCAddress(), isSigner)
}
