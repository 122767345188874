/*


{"USDTContract":"0x5315BBA69196449BAC3c1eD8A68EecDd101b771B",
"TraderContract":"0x90647382c6777890D7a0E935D268bf8610854918",
"OTCContract":"0x856de1Ba686f2BfaFdAAD4Cf2E9491a3abFa5a30"}


 */

export default {
    swapRouter: {
        56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        97: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
        31337: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    },
    multiCall: {
        56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
        97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
        31337: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    },
    USDT: {
        56: '0x55d398326f99059fF775485246999027B3197955',
        97: '0x5315BBA69196449BAC3c1eD8A68EecDd101b771B',
        31337: '0x55d398326f99059fF775485246999027B3197955',
    },
    Trader: {
        56: 'XXX',
        97: '0xd3b2EAa1Cee27df5aE533b8fFE978AC86599414E',
        31337: 'XXX',
    },
    OTC: {
        56: 'XXX',
        97: '0x157165F6b710d757954C5942D04015C38F81423f',
        31337: 'XXX',
    },
}
