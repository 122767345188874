import { StaticJsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import getNodeUrl from "./getRpcUrl";

const RPC_URL = getNodeUrl()

export const simpleRpcProvider = new StaticJsonRpcProvider(RPC_URL)

export const web3Provider = () => {
    return new Web3Provider(window.ethereum)
}

export default null
