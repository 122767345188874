import addresses from '../config/constants/contracts'

export const getAddress = (address) => {
  const chainId = process.env.VUE_APP_CHAIN_ID
  return address[chainId]
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}

export const getUSDTAddress = () => {
  return getAddress(addresses.USDT)
}

export const getTraderAddress = () => {
  return getAddress(addresses.Trader)
}

export const getOTCAddress = () => {
  return getAddress(addresses.OTC)
}
